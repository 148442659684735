.vertical-timeline-element-content{
  &.ticket {
    background-color: red;
  }
  .date {
    color: rgb(0, 0, 0);
    width: max-content;
    padding: .3em;
    border-radius: 10px;
    background-color: rgb(237, 237, 237);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  }
}