@import './globalStyle.scss';

//dark mode
.App {
  font-family: $Regularfont;
  text-align: center;
  font-size: 1.4vw;
  width: inherit;
  margin: 0;
  height: 100vh;
  position: relative;

  @media screen and (max-width: 758px) {
    font-size: 1em;
  }
  h3 {
    font-family: $Boldfont;
  }
  ._power {
    display: flex;
    background: $primary_color;
    width: 100%;
    margin: 1rem 0;
    .text {
      background: $primary_color;
      width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      svg {
        color: $white_text;
        margin: 2rem;
      }
      p {
        margin-top: auto;
        margin-bottom: auto;
        color: $white_text;
      }
    }
    .ndalaict {
      background: rgb(255, 255, 255);
      width: 50%;
      padding: 1rem;
      position: relative;
      .logo {
        img {
          position: relative;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          align-self: center;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .link {
        opacity: 1;
        position: absolute;
        width: 100%;
        transition: opacity ease 0.4s;
        height: 100%;
        margin: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        .site {
          background-color: #033053;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          a {
            background-color: #00213a00;
            border-radius: 0;
            outline-color: $secondary_color;
            border: solid 2px $secondary_color;
            padding: 1rem;
          }
        }
      }
      &:hover {
        .link {
          opacity: 1;
        }
      }
    }
  }

  //Navbar
  .navBar {
    display: flex;
    width: 100%;
    top: 0;
    position: sticky;
    z-index: 100;

    .logo {
      position: relative;
      display: flex;
      flex: 0.5;
      text-align: center;
      align-items: flex-start;
      justify-content: flex-start;
      height: 100%;
      width: 100%;
      a {
        position: relative;
        height: auto;
        width: 100%;
        background-color: $secondary_color;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      a > img {
        position: relative;
        width: 80%;
        margin: 1rem;
      }
      .menubar {
        display: flex;
        color: #033053;
        width: 100%;
        height: 100%;
        margin-top: auto;
        margin-bottom: 0.3rem;
        svg {
          cursor: pointer;
          margin-left: auto;
          padding: 0;
          font-size: 2.5rem;
        }
      }
      @media screen and (min-width: 769px) {
        align-items: flex-start;
        .menubar {
          display: none;
        }
        a {
          display: flex;
          justify-content: flex-start;
          width: 100%;

          img {
            width: 60%;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
    .contact {
      background: $secondary_color;
      flex: 2;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      ul {
        display: flex;
        list-style: none;
        align-items: flex-start;
        margin-left: auto;
        height: 100%;
        justify-content: flex-start;

        text-align: start;
        margin-bottom: auto;
        li {
          width: fit-content;
          display: flex;
          align-items: flex-start;
          padding: .6rem 1rem;

          a {
            font-size: 1rem;
            text-decoration: none;
            color: $black_text;
            width: fit-content;
            font-family: $lightfont;
          }
        }
      }
    }
    .navigation {
      position: absolute;
      bottom: 0;
      transform: translateY(50%);
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      left: 0;
      transition: all ease 0.3s;

      .navbarlist {
        position: relative;
        top: 0;
        width: 75%;
        margin: auto;
        background-color: $primary_color;
        display: flex;
        height: 4em;
        //box-shadow: 0px 0px 3px 1px #021d31;
        justify-content: center;
        align-items: center;
        font-family: "RobotoCondensedLight";
        .mobile-close-menu {
          display: none;
        }

        .list {
          position: relative;
          height: 100%;
          list-style: none;
          display: flex;
          justify-content: center;
          margin: 0;
          padding: 0;
          width: 100%;
          .listitem {
            height: 100%;
            position: relative;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            width: 100%;
            flex-direction: column;

            a,
            p {
              position: relative;
              color: $white_text;
              text-decoration: none;
              display: flex;
              align-items: center;
              height: 100%;
              margin: auto;
              width: 100%;
              justify-content: center;

              &:hover {
                background-color: $lightBlue;
              }
            }
            .servicelist {
              display: none;
              flex-direction: column;
              position: absolute;
              opacity: 1;
              top: 100%;
              width: 12em;
              padding: 0;
              list-style: none;
              height: fit-content;

              li {
                position: relative;
                width: 100%;
                height: fit-content;
                padding: 0.7em 0;
                margin: 0.1rem 0;
                &:hover {
                  background-color: $lightBlue;
                }
              }
            }

            &.service {
              &:hover {
                .servicelist {
                  display: flex;
                  flex-direction: column;
                  position: absolute;
                  opacity: 1;
                  top: 100%;
                  width: 12em;
                  padding: 0rem;
                  list-style: none;
                  height: fit-content;
                  li {
                    position: relative;
                    width: 100%;
                    height: fit-content;
                    padding: 0.8em 0;
                    background: $primary_color;
                    margin: 0.1rem 0;
                    &:hover {
                      background-color: $lightBlue;
                    }
                  }
                }
              }
            }
          }
        }
        .line {
          display: block;
          color: white;
        }
        .login_signup {
          display: flex;

          width: 45%;
          justify-content: center;
          align-items: center;
          height: 100%;

          .login {
            position: relative;

            width: 50%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            .login_client {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              &:hover {
                color: white;
                background-color: $lightBlue;
              }
            }
            ul {
              position: absolute;
              background-color: $primary_color;
              padding: 0em;
              display: none;
              left: 0;
              right: 0;
              list-style: none;
              top: 100%;
              box-shadow: 0 0 2px black;
              a {
                width: 100%;
                text-decoration: none;
              }
              &:hover {
                display: flex;
              }
              li {
                padding: 1rem 0.2em;
                &:hover {
                  background-color: $lightBlue;
                  color: white;
                }
              }
            }
            &:hover {
              ul {
                display: block;
              }
            }
            button {
              font-size: 1.2vw;
            }
          }
          .signup {
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            height: 100%;
            width: 50%;
            .sign_up_list {
              display: none;
              flex-direction: column;
              position: absolute;
              opacity: 1;
              top: 100%;
              width: 12em;
              padding: 0;
              list-style: none;
              height: fit-content;
              li {
                position: relative;
                width: 100%;
                height: fit-content;
                padding: 0.8em 0;
                background: $primary_color;
                margin: 0.1rem 0;
                &:hover {
                  background-color: $lightBlue;
                }
              }
              &:hover {
              }

            }

            a {
              text-decoration: none;
              padding: 0 1rem;
              color: $white_text;
            }
            &:hover {
              background-color: $lightBlue;
              .sign_up_list {
                display: flex;
              }
            }
            @media screen and (max-width:476px) {
              padding:0em 2em;

           }
          }
        }
      }
      &.closed {
        width: 100%;
        transform: translateX(-100%);
        opacity: 0;
        transition: all ease 1s;
      }
    }

    .fixed {
      position: fixed;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
      justify-content: center;
      width: 100%;
      .logo {
        position: relative;
        display: flex;
        flex: 1;
        background-color: $secondary_color;
        padding: 1% 2%;
        text-align: center;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        img {
          position: relative;
          height: 3em;
          margin: 0 auto;
        }
        .menubar {
          flex: 0.5;
        }
      }
      .contact {
        flex: 1;
        ul {
          list-style: none;
          align-items: start;
          margin: auto;
          padding: 0;
          height: fit-content;
          justify-content: space-evenly;
          background-color: rgb(235, 235, 235);
          display: flex;
          flex-direction: row;
          width: 100%;
          li {
            padding: 0 0.5em;
            margin: 0;
            a {
              padding: 0rem;
              text-decoration: none;
              color: $black_text;
              font-size: 1em;
            }
          }
        }
      }
      .navigation {
        position: fixed;
        z-index: 1000;
        top: 0;
        right: 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        left: 0;
        bottom: 0;
        transform: translateY(0%);
        height: 100%;
        .navbarlist {
          position: relative;
          width: 100%;
          background-color: $primary_color;
          display: flex;
          flex-direction: column;
          height: 100%;
          z-index: 200;
          .mobile-close-menu {
            display: flex;
            color: white;
          }
          .list {
            position: relative;
            height: 50%;
            list-style: none;
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin: 0;
            padding: 0;
            width: 100%;
            .listitem {
              height: 100%;
              position: relative;
              margin: 0;
              padding: 0;
              display: flex;
              align-items: center;
              width: 100%;
              flex-direction: column;
              a {
                position: relative;
                color: $white_text;
                text-decoration: none;
                display: flex;
                align-items: center;
                height: 100%;
                margin: auto;
                width: 100%;
                justify-content: center;

                &:hover {
                  background-color: $lightBlue;
                }
              }
              .servicelist {
                display: none;
                flex-direction: column;
                position: absolute;
                opacity: 1;
                top: 100%;
                width: 100%;
                padding: 0rem;
                list-style: none;
                height: fit-content;
                box-shadow: 2px 2px 20px 3px black;
                li {
                  position: relative;
                  width: 100%;
                  height: fit-content;
                  padding: 0em 0;
                  background: $primary_color !important;
                  margin: 0;
                  z-index: 100;
                  &:hover {

                  }
                  a {
                    background-color: $primary_color;
                  }
                }
              }

              &.service {
                &:hover {
                  .servicelist {
                    display: flex;
                    width: 100%;

                    li {
                      margin: -0.1em;
                    }
                  }
                }
              }
            }
          }
          .line {
            display: none;
          }
          .login_signup {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            height: fit-content;
            flex-direction: column;

            .login {
              width: 100%;
              padding: 1.5em 0;
              &:hover{
                padding: 1.5em 0;

              }

            }
            .signup {
              display: flex;
              padding: 1.5em 0;
              width: 100%;
              position: relative;
              a {
                text-decoration: none;
                padding: 0 1rem;
                color: $white_text;
              }
              ul {
                margin: 0;
                padding: 0;
                list-style: none;
                background-color: $lightBlue;
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 425px) {
      flex-direction: column-reverse;
      justify-content: center;
      width: 100%;
      .logo {
        position: relative;
        display: flex;
        flex: 1;
        background-color: $secondary_color;
        padding: 1% 2%;
        text-align: center;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        img {
          position: relative;
          height: 3em;
          margin: 0 auto;
        }
        .menubar {
          flex: 0.5;
        }
      }
      .contact {
        flex: 1;
        ul {
          list-style: none;
          align-items: start;
          margin: auto;
          padding: 0;
          height: fit-content;
          justify-content: space-evenly;
          background-color: rgb(235, 235, 235);
          display: flex;
          flex-direction: column;
          width: 100%;
          li {
            padding: 0 0.5em;
            margin: 0;
            a {
              padding: 0rem;
              text-decoration: none;
              color: $black_text;
              font-size: 0.9em;
            }
          }
        }
      }
      .navigation {
        position: fixed;
        z-index: 1000;
        top: 0;
        right: 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        left: 0;
        bottom: 0;
        transform: translateY(0%);
        height: 100%;
        .navbarlist {
          position: relative;
          width: 100%;
          background-color: $primary_color;
          display: flex;
          flex-direction: column;
          height: 100%;
          z-index: 200;
          .mobile-close-menu {
            display: flex;
            color: white;
          }
          .list {
            position: relative;
            height: 50%;
            list-style: none;
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin: 0;
            padding: 0;
            width: 100%;
            .listitem {
              height: 100%;
              position: relative;
              margin: 0;
              padding: 0;
              display: flex;
              align-items: center;
              width: 100%;
              flex-direction: column;
              &:hover{
                background-color: rgb(192, 192, 192);
              }
              p {
                &:hover{
                  background-color: rgb(192, 192, 192);
                  color: #021d31;
                }
              }
              a {
                position: relative;
                color: $white_text;
                text-decoration: none;
                display: flex;
                align-items: center;
                height: 100%;
                margin: auto;
                width: 100%;
                justify-content: center;

                &:hover {
                  background-color: rgb(192, 192, 192);
                  color: #021d31;
                }
              }
              .servicelist {
                display: none;
                flex-direction: column;
                position: absolute;
                opacity: 1;
                top: 100%;
                width: 100%;
                padding: 0rem;
                list-style: none;
                height: fit-content;
                box-shadow: 2px 2px 20px 3px black;
                li {
                  position: relative;
                  width: 100%;
                  height: fit-content;
                  padding: 0em 0;
                  margin: 0;
                  z-index: 100;

                  &:hover{
                    background-color: rgb(192, 192, 192);
                    color: #021d31;
                  }
                  a {
                    background-color: rgba(255, 255, 255, 0);
                    height: 100%;
                  }
                }
              }

              &.service {
                &:hover {
                  .servicelist {
                    display: flex;
                    width: 100%;

                    li {
                      padding: 1em 0;
                      &:hover{
                        background-color: rgb(192, 192, 192) !important;
                      }

                    }
                  }
                }
              }
            }
          }
          .line {
            display: none;
          }
          .login_signup {

            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            height: fit-content;
            flex-direction: column;
            .login {
              padding: 0.5em 0;
              ul {
                width: 100%;
                left: 0;
              }
            }
            .signup {
              display: flex;
               font-size: 1rem;
              a {
                text-decoration: none;
                padding: 0 1rem;
                color: $white_text;
              }
            }
          }
        }
      }
    }
  }

  .div {
    height: 100%;
  }
  //Landing Page
  .landing__page {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 100%;
    img {
      left: 0;
      top: 0;
      bottom: 0;
      position: absolute;
      object-fit: cover;
      height: 100%;
      width: inherit;
    }
    .css-8atqhb {
      height: 100%;
      display: flex;
      .css-gmuwbf {
        height: 70%;
      }
    }
    .inner__body {
      position: relative;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      .landing__page__top {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        color: $white_text;
        margin: 5%;
        h3 {
          font-size: 2em;
          padding-top: 4rem;
        }
        p {
          color: silver;
          margin: 1rem;
        }
        .landingpageCard {
          background-color: $lightBlueTransparent;
          padding: 1rem 5rem;
          margin: 1.5rem;
        }
        button {
          padding: 0.5rem 2rem;
        }
      }
      .landing__page__services {
        width: fit-content;
        margin: 0;
        .landingCards {
          display: flex;
          flex-direction: row;
          padding: 0;
          margin: 1rem 0;
          width: fit-content;
          .Card {
            background-color: rgba(230, 230, 230, 0.687);
            backdrop-filter: blur(5px);
            margin: 2rem;
            flex: 0.5;
            box-shadow: 0px 0px 15px rgba(184, 184, 184, 0);
            .heading {
              background-color: $primary_color;
              margin: 0;
              display: flex;
              flex-direction: column;
              padding: 0;
              h3 {
                color: $white_text;
                padding: 0 0 0.5rem 0;
                margin: 0;
                font-family: $Regularfont;
              }
              div {
                width: 4rem;
                background: white;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 1rem auto;
                border-radius: 50%;
                height: 4rem;

                svg {
                  width: 65%;
                  margin: 0.5rem auto;
                }
              }
            }
            .body {
              padding: 2rem;
              display: flex;
              height: 100%;
              width: 100%;
              justify-content: center;
            }
            &:hover {
              box-shadow: 0px 0px 15px rgba(184, 184, 184, 0.781);
              transition: all ease 0.3s;
              background-color: white;
            }
          }
        }

        @media screen and (max-width: 768px) {
          .landingCards {
            display: flex;
            flex-direction: column;
            padding: 0;
            margin: 2rem 2rem;
            width: fit-content;
            .Card {
              background-color: white;
              margin: 2rem;
              flex: 0.5;
              .heading {
                background-color: $primary_color;
                width: 100%;
                display: flex;
                flex-direction: column;
                h3 {
                  color: $white_text;
                  padding: 0 0 0.5rem 0;
                  margin: 0;
                }
                div {
                  width: 3rem;
                  background: white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 1rem auto;
                  border-radius: 50%;
                  height: 3rem;

                  svg {
                    width: 65%;
                    margin: 0.5rem auto;
                  }
                }
              }
              .body {
                padding: 1rem 1rem;
              }
            }
          }
          .tabs {
            padding: 3rem;
            .chakra-tabs {
              background: #efefefd6;
              backdrop-filter: blur(4px);
              height: 13rem;
            }
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      .inner__body {
        z-index: 15;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;

        .landing__page__top {
          h3 {
            font-size: 2em;
          }
          p {
            color: silver;
          }
          .landingpageCard {
          }
          button {
          }
        }
        .landing__page__services {
          .landingCards {
            .Card {
              background-color: white;
            }
          }
        }
      }
    }
  }
  .section_1 {
    background-color: $primary_color;
    display: flex;
    text-align: center;
    margin: auto auto;
    justify-content: center;
    align-items: center;
    z-index: 0;
    height: fit-content;
    color: $secondary_color;
    @media screen and (max-width: 600px) {
      height: 100%;
    }
  }
  .section_2 {
    position: relative;
    background-color: $primary_color;
    height: fit-content;
    .picture {
      display: flex;
      img {
        width: 80%;
        height: auto;
      }
    }
    .words {
      position: absolute;
      z-index: 0;
      text-align: center;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      height: 100%;
      top: 0;
      left: 0;
      padding: 1rem;

      .word {
        position: relative;
        line-height: 25px;
        letter-spacing: 2rem;
        height: 100%;
        width: 60%;
        padding: 2em;
        height: 50%;
        display: flex;
        margin-top: auto;
        margin-bottom: auto;
        justify-content: center;
        align-items: center;
        color: $primary_color;
        background-color: #033053;
        backdrop-filter: blur(5px);
        border: #033053 1px solid;

        p {
          color: $secondary_color;

          letter-spacing: 1.5px;
          font-family: $lightfont;
          text-align: center;
          margin: auto;
        }
      }
    }

    @media screen and (max-width: 760px) {
      .picture {
        display: flex;
        position: absolute;
        top: 0;
        bottom: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .words {
        position: relative;
        top: 0%;
        z-index: 1;
        text-align: right;
        width: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
        left: 0;
        .word {
          background: #063b63a9;
          line-height: normal;
          margin-top: 2em;
          height: 100%;
          width: auto;
          padding: 3em;
          p {
          }
        }
      }
    }
  }
  .section_3 {
    position: relative;
    height: 100%;
    background-color: $white_text;
    overflow: hidden;
    width: 100%;

    .bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      filter: blur(4px);
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .m_block {
      height: 100%;
      position: relative;
      padding: 1em 0 5em 0;
      left: 0;
      top: 0;
      background: $secondary_color;
      width: 100%;
      color: $white_text;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      h3 {
        padding: 2em;
        font-size: 1.4em;
        color: black;
      }
      hr {
        margin: 1rem 0;
      }
    }
    .tabs {
      width: 80%;
      background: rgb(255, 255, 255);
      color: black;
      box-shadow: 2px 2px 47px -17px rgba(59, 59, 59, 1);
      -webkit-box-shadow: 2px 2px 47px -17px rgba(59, 59, 59, 1);
      -moz-box-shadow: 2px 2px 47px -17px rgba(59, 59, 59, 1);
      border-radius: 5px;
      .tab {
        margin: 0.5em;
      }
      .tab_inside {
        line-height: 2em;
        padding: 2em;
        h3 {
          font-family: $Boldfont;
          background-color: $primary_color;
          color: $white_text;
          font-size: 1em;
        }
        p {
          font-size: 1em;
        }
      }
    }

    @media screen and (max-width: 768px) {
      .tabs {
        width: 100%;
      }
    }
  }
  .section_5 {
    display: flex;
    flex-direction: row;
    background-color: $primary_color;
    align-items: center;

    position: relative;
    .m_block {
      display: flex;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: inherit;
      font-weight: 600;
      &.one {
        clip-path: polygon(0 0, 100% 0, 82% 100%, 0 100%);
      }
      &.m_block_2 {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: inherit;
        background-color: #4443437c;
        backdrop-filter: blur(3px);
        .text {
          padding: 2em;
          h5 {
            font-size: 1.1em;
            line-height: 25px;
            padding-top: 5em;
            color: $white_text;
          }
        }
      }
      button {
        margin: 2em 1em 1em 1em;
        background-color: $white_text;
        color: $primary_color;
        width: 100%;
      }
    }
    @media screen and (max-width: 767px) {
      .m_block {
        display: flex;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: inherit;
        font-weight: 600;
        position: relative;
        &.one {
          clip-path: none;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          .wrapper {
            background-color: gray;
            width: 100%;
            height: 100%;
            img {
              object-fit: cover;
              height: 100%;
              border: 0.5em solid linear-gradient(gray, white);
            }
          }
        }
        &.m_block_2 {
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          z-index: 1;
          .text {
            padding: 2em;

            h5 {
              font-size: 1.1rem;
              line-height: 25px;
              padding-top: 5em;
            }
            p {
              height: 100%;
            }
          }
        }
        button {
          margin: 4em 1em 1em 1em;
          background-color: $primary_color;
          color: $white_text;
        }
      }
    }
  }
  .section_6 {
    padding: 3rem 2rem 4rem 2rem;
    position: relative;
    background-color: $secondary_color;
    .heading {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      h3 {
        font-size: 1.4rem;
        font-weight: 600;
        font-family: $Boldfont;
      }
      hr {
        width: 40%;
        height: 1rem;
        border-top-width: 1px;
        border-color: black;
        margin: 1rem 0 3rem 0;
      }
    }
    .m_block {
      .swiper {
        padding: 1rem;
      }
    }
    .contact_us {
      position: absolute;
      z-index: 20;
      bottom: 0;
      transform: translateY(50%);
      left: 20%;
      width: 60%;
      color: $white_text;
      background-image: url("./Assets/laptop.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .contact_us_inner {
        background-color: rgba(31, 31, 31, 0.301);

        backdrop-filter: blur(1px);
        .words {
          padding: 2em;
        }
        button {
          color: black;
          width: 10em;
          padding: 1em;
          align-self: flex-end;
          margin: 1em;
          border-radius: 5px;
        }
      }
    }
  }
  .footer {
    background-color: $primary_color;
    color: $white_text;
    padding: 2.5em 0 0 0;
    text-decoration: none;

    .top {
      display: flex;
      flex-direction: row;
      padding: 0.5em;
      .about {
        padding: 3em;
        width: 29em;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .sitemap {
        padding: 2em;
        width: 30em;
        h3 {
          font-weight: 600;
          padding: 1em;
        }
        .link_list {
          padding: 1em;
          text-align: start;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto;
          grid-gap: 10px;
          grid-auto-flow: row;
          text-decoration: none;
          a {
            text-decoration: none;
          }

          .services {
            display: flex;
            flex-direction: column;
            grid-row: 2/ 5;
            grid-column: 1/2;
            height: 100%;

            text-decoration: none !important;
            .servicelist {
              display: flex;
              align-items: center;
              width: 100%;
            }
            a {
              margin: 0.5rem;
              text-decoration: none;
              font-size: 0.8em;
              line-height: 1em;
              font-family: $lightfont;
              @media screen and (max-width: 768px) {
                line-height: 1rem;
              }
              &:hover {
                font-size: 0.85em;

                transition: all ease-in 0.1s;
              }
            }
          }
        }
      }
      .logo {
        padding: 3em;
        width: 29em;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
          height: auto;
          padding: 0;
          margin: 0;
          img {
            color: #fff;

            image-rendering: pixelated;
            image-rendering: -webkit-optimize-contrast;
          }
        }
      }
      @media screen and (max-width: 768px) {
        flex-direction: column;
        .about {
          width: auto;
          padding: 5em 2.5em;
        }
        .sitemap {
          width: auto;
          padding: 0em;
        }
        .logo {
          width: auto;
          padding: 0.5em;
        }
      }
    }
    hr {
      width: 100%;
    }
    .bottom {
      h4 {
        padding: 1rem;
      }
      .t_c {
        a {
          padding: 0.5rem;
        }
      }
      .pop {
        display: flex;
        flex-direction: row;
        height: 100%;
        .popi_act {
          display: flex;
          padding: 0 1rem;
          width: 100%;
          align-items: center;
          justify-content: flex-start;
          img {
            height: 50%;
          }
        }
        .t_c {
          display: flex;
          width: 100%;
          text-align: center;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
        .ndalaictlogo {
          width: 100%;
          display: flex;
          margin-top: auto;
          margin-bottom: auto;
          margin-left: auto;
          margin-right: 0;
          justify-content: flex-end;

          div {
            height: 100%;
            width: 100%;
            display: flex;
            img {
              margin-left: auto;
              background-color: rgba(255, 255, 255, 0);
              width: 15em;
            }
          }
        }
      }
    }
  }
  //second block

  //About us Page
  .AboutUs_page {
    .body {
      padding: 3em;
      width: 100%;
      background: rgb(241, 241, 241);
      margin: auto;

      .image {
        background: white;
        padding: 2rem;
        box-shadow: 2px -1px 85px 4px rgba(59, 59, 59, 0.09);
        -webkit-box-shadow: 2px -1px 85px 4px rgba(59, 59, 59, 0.09);
        -moz-box-shadow: 2px -1px 85px 4px rgba(59, 59, 59, 0.09);
        width: 100%;
        img {
          margin-left: auto;
          margin-right: auto;
        }
      }
      .text {
        padding: 4rem;
        p {
          color: $black_text;
          line-height: 1.5em;
        }

        @media screen and (max-width: 768px) {
          padding: 2rem 0;
        }
      }
    }
  }

  .faqs_page {
    .body {
      background: rgb(241, 241, 241);
      padding: 2rem;
      .list {
        padding: 2rem;
        box-shadow: 2px -1px 85px 4px rgba(59, 59, 59, 0.09);
        -webkit-box-shadow: 2px -1px 85px 4px rgba(59, 59, 59, 0.09);
        -moz-box-shadow: 2px -1px 85px 4px rgba(59, 59, 59, 0.09);
        > h1 {
          font-size: 1.5rem;
          padding: 1rem;
        }
        a {
          color: $primary_color;
          font-size: 1.4rem;
        }
        .Card {
          text-align: start;
          padding: 1rem;
          display: flex;
          flex-direction: column;

          h3,
          p {
            margin: 0.5rem;
            width: 100%;
          }
          h3 {
            color: $primary_color;
            width: 100%;
          }
          p {
            color: gray;
          }
        }
      }
    }
  }
  .contactus_page {
    height: 100%;

    .body {
      background: rgb(212, 212, 212);
      padding: 4rem;
      .heading {
        font-size: 1.4rem;
      }
      .contacts_and_form {
        display: flex;
        width: 100%;
        align-items: center;

        .contacts {
          flex: 0.6;
          .Card {
            background-color: $primary_color;
            display: flex;
            margin: 1rem;
            box-shadow: 2px 0 7px 2px rgba(59, 59, 59, 0.09);
            svg {
              flex: 0.3;
              width: 100%;
              font-size: 2rem;
              margin: 1rem;
              background-color: $primary_color;
              path {
                color: $white_text;
              }
            }
            a {
              flex: 1.5;
              background-color: $secondary_color;
              font-size: 1.4rem;
              margin: 0;
              padding: 0;
              &:hover {
                background-color: $primary_color;
                color: $white_text;
              }
              p {
                padding: 1rem;

                height: 100%;
                text-align: center;
              }
            }
          }
        }
        .form {
          flex: 1;
          display: flex;
          flex-direction: column;
          width: 100%;

          form {
            width: 100%;
            margin: 1rem;
            display: flex;
            flex-direction: column;
            .form_entry {
              flex: 1;
              width: 100%;
              height: auto;
              margin: 1rem;
              display: flex;
              label {
                padding: 1rem;
                width: 50%;
              }
            }
          }
        }
      }
    }
  }
  .enduser_page,
  .InfrastructureServices_page,
  .sales_page,
  .faqs_page,
  .pricingList,
  .downloads,
  .agreementTerms {
    .body {
      padding: 2rem;
      background: rgb(241, 241, 241);
      .heading {
        position: relative;
        > div {
          h3 {
            position: absolute;
            font-size: 2em;
            padding: 2rem;
            width: 100%;
            height: 20rem;

            color: $white_text;
            background-color: rgba(95, 95, 95, 0.24);
            backdrop-filter: blur(2px);
            display: flex;
            justify-content: center;
            align-items: center;
            filter: saturate(1.5);
          }
          > img {
            position: inherit;
            height: 20rem;
            width: 100%;
            object-fit: cover;
          }
        }
      }
      .list {
        padding: 5rem 2rem;
        .Card {
          display: flex;
          align-items: center;
          margin-bottom: 2rem;
          margin-top: 2rem;
          h5 {
            width: fit-content;
          }
        }
      }
    }
  }
  .signup_page {
    .body {
      padding: 2.5rem;
      color: $black_text;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: rgb(248, 248, 248);
      align-items: center;
      h3 {
        font-size: 1.4rem;
        padding: 1.5rem 0 1rem 0;
      }
      hr {
        border-color: black;
        width: 50%;
      }
      .inner_cards {
        padding: 2rem;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        form {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 50%;
          align-items: center;

          padding: 1rem;
          border-radius: 5px;
          hr {
            border-color: black;
            margin: 1rem;
            width: 95%;
          }
          .radiogroup {
            padding: 1rem 0 0 0;
          }
          .organisation {
            width: 100%;
          }
          button {
            width: 50%;
            margin: 2rem;
            background-color: $primary_color;
            color: $white_text;
            &:hover {
              background-color: #0f3b5c;
            }
          }
          a {
            color: gray;
            &:hover {
              color: black;
            }
          }
          .Card {
            display: flex;
            width: 100%;
            position: relative;
            justify-content: space-around;
            align-items: center;
            flex-direction: row;
            padding: 0.7rem;
            label {
              padding: 0.5rem 0 0.1rem 0;
              width: 15rem;
              text-align: start;
            }
            input {
              background-color: $white_text;
            }
            .message {
              position: absolute;
              bottom: -1rem;
              z-index: 10;
              left: 30%;
              color: rgb(196, 68, 68);
              text-align: start;
              font-size: 14px;
              @media screen and (max-width: 768px) {
                font-size: 9px;
              }
            }
          }
        }
      }

      @media screen and (max-width: 768px) {
        padding: 0;
        .inner_cards {
          padding: 2rem 0;
          form {
            width: 100%;
          }
        }
      }
    }
    .body_back {
    }
  }
  .login_page {
    position: relative;
    .body {
      height: 100%;
      padding: 2em;
      color: $black_text;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-position: 0 0;
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      align-items: center;
      background-color: #d9d9d9;
      margin-right: auto;
      margin-left: auto;
      .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(4px);
        padding: 1em;

        h3 {
          font-size: 20px;
          padding: 1em;
          margin-top: 0;
        }
        hr {
          border-color: $black_text;
          padding: 1em;
          width: 50%;
        }
        width: 100%;
        .inner_cards {
          display: flex;
          flex-direction: column;
          padding: 0 0;
          width: 100%;
          justify-content: center;
          align-items: center;
          height: fit-content;

        }
      }

      @media screen and (max-width: 768px) {
        padding: 2rem 0;
        .container {
          .inner_cards {
            form {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .Error404 {
    height: 100vh;
    .body {
      position: relative;
      img {
        height: 70vh;
        width: 100%;
      }
      .text {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background: rgba(109, 109, 109, 0.521);
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        backdrop-filter: blur(5px);
        h3 {
          font-size: 1.7rem;
        }
        p {
          color: $white_text;
        }
      }
    }
  }
  .verification_page {
    position: relative;
    .body {
      position: inherit;
      height: 80vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #d9d9d9;
      margin-top: auto;
      .otp {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 2rem;
        h3 {
          padding: 2.5rem;
          font-size: 1.4rem;
          font-family: $Regularfont;
        }
        input {
          background-color: $white_text;
          margin: 2rem;
          text-align: center;
        }
      }
    }
  }
  .client_user_profile {
    .top {
      padding: 1rem;
      img {
      }
      label {
        color: white;
        background-color: rgb(235, 235, 235);
        border-radius: 50%;
        height: 3rem;
        width: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid gray;
        svg {
          color: #033053;
        }
        &:hover {
          background-color: #033053;
          svg {
            color: white;
          }
          transition: all 0.1s;
        }
        &:active {
        }
      }
    }
  }

  .new_password {
    .body {
      input {
        font-family: $lightfont;
      }
      button {
        background-color: $primary_color;
        svg {
          color: $secondary_color;
        }
      }
    }
  }
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background-color: #ffffff;
}
::-webkit-scrollbar-thumb {
  background-color: #033053;
  border-radius: 10px;
}
