
.p-fileupload{
    
    .p-fileupload-buttonbar{

    }
    .p-fileupload-content{

    }
 
    .p-button {
    
        &.p-fileupload-choose{
            background-color: #124d7a;
        }
        &.p-component:nth-child(2) {
            background-color: #124d7a;
            color:#f1f1f1;
            &:disabled{
                color: rgb(158, 158, 158);
            }
        }
        &.p-component:last-child {
            background-color: #f1f1f1;
            color: #06263f;
        }
    }
}
