.p-treeselect	{
     padding:.1em;
}
.p-treeselect-label-container	{

}
.p-treeselect-label{
    margin: .2em;
}
.p-treeselect-trigger{

    padding:.1em;

}
.p-treeselect-panel	{
    border-radius: 3px;
    box-shadow: #081c2d 1px 1px 4px 1px ;
    padding:.3em;
    .p-treeselect-header {
        padding:.4em;
    }

}

.p-treeselect-items-wrapper	{

}
.p-tree {

    .p-component {
        .p-tree-selectable {

        }
    }
}

.p-treenode {
    .p-treenode-leaf {
    }
}
.p-treenode-content {
    padding:.2em;
    margin:.2em;
    border:solid .5px gray;
    border-radius: 3px;
    background-color:whitesmoke;

    .p-treenode-selectable {
        .p-highlight {

        }
    }
}
p-dropdown {
    height: fit-content;
    padding:0;
    box-shadow: 0 0 20px 20px gray;

}
.p-dropdown-header {
    display:flex;
    flex-direction:row;

}
.p-dropdown-filter-container{
    width:90%;
}
.p-dropdown-panel {
    border-radius:15px;
    background-color:gray;
}
