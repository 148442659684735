$primary_color: #033053;
$primary_colorTransparent: #00213ad8;
$secondary_color: #ffffff;
$lightBlue: #4262b7;
$lightBlueTransparent: #304c9fbd;
$gray: #d9d9d9;
$black_text: black;
$white_text: white;
$lightfont: "RobotoCondensedLight";
$Boldfont: "RobotoCondensedBold";
$Regularfont: "RobotoCondensedRegular";


.notification-slide {
  position: relative;
  margin: 0 0;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  overflow: hidden;
  

  &--ticket {
    background-color: #1B5E20;
    color: #fefefe;
  }

  &--message {
    background-color:$lightBlueTransparent;
    color: #fefefe;
  }

  &--error {
    background-color: #B71C1C;
    color: #fefefe;
  }
  &--service{
    background-color: $primary_color;
    color: #fefefe;
  }

  & .notification-slide__options {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: currentColor;

    & span {
      padding: 0 0.2em;
      font-size: '.8em';

      & button {
        cursor: pointer;
        color: currentColor;
        padding: 1em;
        margin: 0;
        margin-right: 0.2em;
        border: 0;
        border-radius: 50%;
        background-color: transparent;

        &>svg {
          height: 1em;
          width: 2em;
          fill: currentColor;
        }

        &:hover, &:focus {
          background-color: rgba(255, 255, 255, 0.1);
        }

        &:active {
          background-color: rgba(255, 255, 255, 0.15);
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
  
  & .notification-slide__outline {
    content: '';
    z-index: 2;
    border-radius: 4px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    box-shadow: inset 0 0 0 1px #fefefe, inset 0 0 0 3px #01579B, inset 0 0 0 4px #fefefe;
    opacity: 0;
  }

  & .notification-slide__info {
    z-index: 1;
    position: relative;
    border: 0;
    margin: 0;
    border-radius: 0;
    text-align: left;
    will-change: transform;
    font-size: 1rem;
    cursor: grab;
    background-color: #fefefe;
    user-select: none;
    padding: 1.1em 1em;
    transition-property: transform;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
    

  &:focus-visible {
    outline: none;
    
    & ~ .notification-slide__outline {
      opacity: 1;
    }
  }
  

    &>.notification-slide__heading {
      display: inline-block;
      font-weight: 600;
      font-size: 1.3m;
      margin: 0;
      margin-bottom: 0.5em;
      color: #212121;

    }
    &>.notification_message{
      font-size: .8em;
    }
    
    &>span {
      color: #616161;
      font-size: 1em;
      line-height: 1em;
      
      
    }

    &.notification-slide__options--user-control {
      transition: none;
      cursor: grabbing;
    }
    
    &:hover:not(.notification-slide__options--user-control) {
      transform: translateX(-5px);
    }
  }

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
  