
#Ellipse1{
  animation-name: spinner ;
  animation-duration: 1.2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform-origin: center;
  color:red;
}
#Ellipse2 {
  animation-name: spinner ;
  animation-duration: 1.1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform-origin: center;
  color:red;
}
#Ellipse3 {
  animation-name: spinner ;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform-origin: center;
  color:red;
}
@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}

