@font-face {
  font-family: "RobotoCondensedBold";
  src: local("GoldmanBold"),
    url("./Assets/fonts/RobotoCondensed-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "RobotoCondensedLight";
  src: local("GoldmanBold"),
    url("./Assets/fonts/RobotoCondensed-Light.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "RobotoCondensedRegular";
  src: local("GoldmanBold"),
    url("./Assets/fonts/RobotoCondensed-Regular.ttf") format("truetype");
  font-weight: bold;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

*{
 margin: 0;
 padding: 0;
 box-sizing: border-box; // not neccesary
}
body {
  margin: 0;
  padding: 0;
  font-size: 1.9vw;
  font-family: "RobotoCondensedRegular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
