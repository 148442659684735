@import './../../../globalStyle.scss';
.client_navbar {
   .nav_bar {
        background-color:rgb(255, 255, 255);
        display: flex;
        flex-direction: row;
        padding:.9em;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        border-bottom: .1px solid rgb(238, 238, 238);
        >button {
            height: fit-content;
            margin:.5em;
            display: none;
            @media  (max-width:600px) {
                display: block;
                box-shadow: 0px 0px 5px 1px rgb(202, 202, 202);
            }

        }
        img {
            width: 150px;
        }

        .client_navigation_bar_right {
            display: flex;
            flex-direction: row;
            align-items: center;
          div>span {
            margin: auto;

          }

        }

   }
}