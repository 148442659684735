
.p-fileupload .p-fileupload-content .p-message-error {
  background-color: rgba(222, 105, 105, 0.65);
  .p-message-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    >svg {
      display: none;
    }
    span {
      text-align: start;

    }
    .p-message-detail {
      background-color: red;
      padding:.2em;
      border-radius: 5px;
    }
    button {
      border:1px solid black;
      border-radius: 50%;
    }

  }
}
