.client_interface {
    height: 100%;
    position: relative;
    width: inherit;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
#transactionViewColumn {
    padding:0 !important;
    margin:0 !important;
    .rs-table-cell-content {
        padding:0 !important;
        margin:auto !important;
        height: 100%;
        display:flex;
        justify-content: center;
        align-items: center;
    }
}
