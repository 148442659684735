
.p-dropdown {
  background: #e3e3e3;
  border-radius: 5px;
  width:100%;
  padding:0;
}
.p-dropdown-panel {
  background:white;
  padding:5px;
}
.p-inputtext{
  padding:.05em !important;
}

.p-dropdown-filter{background:whitesmoke;}
.p-dropdown-filter:focus-visible{border:none}

.p-dropdown-items{padding:5px;}
.p-dropdown-item{padding:5px;}
.p-dropdown-filter-container{
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding:2px;
  margin:0;
  input{
    padding:5px;
    &:focus-visible {
      border:none;
      outline:none;
    }
  }


}
.p-dropdown-items-wrapper{padding:5px;}
.p-dropdown-label{ margin:auto;}
.p-dropdown-open{padding:5px;}
.p-fileupload {
  background-color:white;
  .p-fileupload-buttonbar {
    padding:.5em;
    justify-content: start;

  }
  .p-button {
    margin:.2em;
    padding:.3em .5em;
    border-radius: 5px;
    background-color: #847eeb;
    color: white;
    svg {
      margin:.3em;
    }
    &:disabled {
      background-color: #e7e7e7 !important;
      color: #9a9a9a;
    }
  }
  .uploadButton {
    background-color: #36d236;
    color: black;
  }
  .clearButton {
    background-color: #d5d5d5;
    color: black;
  }
  .p-fileupload-choose {
    background-color: dodgerblue;
  }
  .p-fileupload-content{
    padding:.5em 1em;
    background-color: rgb(231, 231, 231);
    .p-fileupload-row{
      img {
        margin:.2em;
      }
      .p-badge-warning{
        background-color:rgb(235, 169, 48);
      }
      .p-badge-success {
        background-color: rgba(64, 177, 64, 0.767);
      }
    }
    .p-message-error {
      background-color: #ec3c3c9c;
      padding:.3em;
      border-radius: 5px;
      .p-message-summary {
        margin:0 .2em ;
      }
      .p-message-detail {
        margin:.3em;
        font-weight: bold;
      }
      svg {
        margin:.1em;
        font-size: 40px;
      }
    }
  }
}
.p-listbox	{

  border-radius: 3px;
  padding:.6em 0;
  background-color: #f6f6f6;
  .p-listbox-header{
    padding:0 1em 0 0;
    .p-listbox-filter-container{
      padding:.5rem;
      input {
        border-radius: 6px;
        border-color: #c9c9c9;
        border-width: 1px;
        padding:.3em;
        &:focus-visible{
          outline-color: #2b96ef;
          outline-width: .5px;
        }
      }
      svg {
        margin-left: .2em;
      }
    }
  }
  .p-listbox-list-wrapper{
    padding:0 1em 0 0;
    .p-virtualscroller{
      overflow-x: hidden;
      .p-listbox-list{
        background-color: white;
        border-radius: 5px;
        padding:.1em;
        margin:.5em;

        .p-listbox-item{
          border-radius: 5px;
          padding: .5em;
          margin: .5em;
          border-width: .1em;
          border-color: #f3f3f3;
          background-color: #e8e8e8;
          &:hover {
            background-color: rgba(19, 65, 110, 0.8);
            color: white;
          }
          &.p-highlight{
            background-color: #033053;
            color: white;
          }
        }
      }
    }
  }
}
