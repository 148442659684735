.p-menubar {
  background-color: white;
  font-family:RobotoCondensedBold;
  border-radius: 0;
  color: black;

}
.p-menu-list {

}
.p-menuitem {
  margin: .5em;
  border-radius: 0;
  background-color: whitesmoke;
 
}
.p-menuitem-text {
}


.p-menuitem-icon {

}