@import './../../../globalStyle.scss';
.client_side_bar {
    background-color: $secondary_color;
    color:black;
    float: left;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    transform: translateX(0%);
    border-right: 0.1px solid rgb(238, 238, 238);
   
    @media screen and (max-width:600px){
        float: none;
        position: absolute;
        width: 100%;
        transform: translateX(-100%);
        transition: transform 0.2s ease-in-out;
        background-color: $primary_color;
        color: $secondary_color;
        z-index: 100;
       
        &.open {
            transform: translateX(0%);
            transition: transform 0.2s ease-in-out;

        }
    }

    .item_list {

        ul {
            margin:0;
            padding: 0;
            display: flex;
            flex-direction: column;
            li {
                :hover {
                    background-color: $primary_color;
                    color: $secondary_color;
                    @media screen and (max-width:600px){
                    color: $primary_color;
                    background-color: $secondary_color;
                    }
                }
                .selected {
                    background-color: $primary_color;
                    color: $secondary_color;
                    @media screen and (max-width:600px){
                        color: $primary_color;
                        background-color: $secondary_color;
                    }
                }
                a {
                display: flex;
                flex-direction: row;
                align-items: center;
               
                padding:.5em 1em;

                p {
                    padding:1em;
                }
                svg {
                    margin:.5em;
                    font-size: 1.5em;
                }
                

            }}
        }
    }
}