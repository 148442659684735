//light mode
$primary_color: #033053;
$primary_colorTransparent: #00213ad8;
$secondary_color: #ffffff;
$lightBlue: #4262b7;
$lightBlueTransparent: #304c9fbd;
$gray: #d9d9d9;
$black_text: black;
$white_text: white;
$lightfont: "RobotoCondensedLight";
$Boldfont: "RobotoCondensedBold";
$Regularfont: "RobotoCondensedRegular";