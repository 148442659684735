$primary_color: #033053;
$primary_colorTransparent: #00213ad8;
$secondary_color: #ffffff;
$lightBlue: #4262b7;
$lightBlueTransparent: #304c9fbd;
$gray: #d9d9d9;
$black_text: black;
$white_text: white;
$lightfont: "RobotoCondensedLight";
$Boldfont: "RobotoCondensedBold";
$Regularfont: "RobotoCondensedRegular";

.client_tickets {
  .ticket_table {
    background-color: rgb(255, 255, 255);
    margin: 1rem 0;
  }
}

.addCard {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  backdrop-filter: blur(3px);
  background-color: rgba(55, 55, 55, 0.5);
  position: absolute;
  z-index: 2;
  .icon-notification {
    color: rgb(197, 197, 197);
    background-color: rgb(238, 238, 238);
    width: 3em;
    height: 3em;
    transition: all 0.3s ease-in;
    &:hover {
      width: 4rem;
      height: 4rem;
      transition: all 0.3s ease;
    }
  }
}
.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
.container {
  margin: 0.3em;
  text-align: center;
  .inner_cards {
    margin: 1em;
    text-align: start;
  }
  form {
    align-items: center;
    display: flex;
    flex-direction: column;
    .error {
      color: red;
      font-size: 0.9em;
      font-style: italic;
    }
    button {
      width: 50%;
      margin-left: auto;
      padding: 0.5em;
      background-color: $primary_color;
      color: $white_text;
      &:hover {
        background-color: #0f3b5c;
      }
    }
  }
}
.chakra-modal__close-btn {
  display: none !important;
}
